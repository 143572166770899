import React, { useContext, useState, useRef } from 'react';
import styled from '@emotion/styled';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { lighten } from 'polished';

import { eligibilityCheck } from 'Api/addressFetchAPI';

import { DefaultContext } from 'Context/DefaultContext';

import { minTablet, max1025, min1280 } from 'Utils/variables';
import colors from 'Utils/theme';
import { gaLogEvent } from 'Utils/analytics';

import MovingPlaceholder from 'Components/moving-placeholder';
import LoadingSpinner from 'Components/loadingSpinner';

import { responsiveFont } from 'Styles/helpers';

import ArrowRight from 'Icons/ArrowRight';
import { StateContext } from 'context/StateContext';

const StyledAutoCompleteWrapper = styled.form`
  position: relative;
  width: 100%;
  z-index: 91;

  @media (min-width: ${minTablet}) {
    max-width: 70%;
  }

  @media (min-width: ${max1025}) {
    max-width: 80%;
  }

  @media (min-width: ${min1280}) {
    max-width: 640px;
  }
`;

const AddressInputWrapper = styled.div`
  margin: 10px 0 0;

  @media (min-width: ${minTablet}) {
    margin: 20px 0 0;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AddressInput = styled.input`
  position: relative;
  z-index: 667;
  margin-top: 0;
  padding-top: ${(props) => (props.active ? '14px' : '0')};
  padding-left: 20px;
  padding-right: 33px;

  width: 100%;
  height: 50px;

  font-size: ${responsiveFont({ sizes: '16, 18' })};

  border-radius: 25px;

  color: ${colors.walmart.blue};
  background-color: ${colors.white};

  transition: border-color 300ms ease-out, padding-top 100ms ease-out;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${colors.inHome.darkGrey};
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${colors.inHome.darkGrey};
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${colors.inHome.darkGrey};
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${colors.inHome.darkGrey};
  }

  @media (min-width: ${max1025}) {
    height: 70px;
    border-radius: 35px;
    padding-top: ${(props) => (props.active ? '20px' : '0')};
    padding-left: 40px;
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

const SuggestionContainer = styled.div`
  position: absolute;
  border-radius: 20px;
  border: ${(props) => (props.hasSuggestions ? 1 : 0)}px solid ${colors.inHome.medGrey};
  overflow: hidden;
  text-align: left;
  z-index: 666;
  margin: 15px auto 0;
  background-color: ${colors.white};
  width: 100%;
`;

const Suggestion = styled.div`
  padding: 15px;
  background-color: ${(props) => (props.active ? colors.inHome.softYellow : colors.white)};
  color: ${colors.walmart.blue};
  cursor: pointer;
`;

const Label = styled.label`
  color: ${colors.white};
  font-weight: 700;
  margin: 5px 0 0;
  width: 100%;
  display: block;
  text-align: left;
  max-width: inherit;
`;

const AddressFootnote = styled.div`
  color: ${colors.white};
  margin: 12px 0 0;

  span {
    font-size: ${responsiveFont({ sizes: '13, 14' })};
  }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 262px;
  background-color: ${colors.white};
`;

const InputButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3px;
  top: 3px;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background-color: ${colors.walmart.yellow};
  --webkit-appearance: none;
  z-index: 668;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: ${(props) => !props.disabled && lighten(0.1, colors.walmart.yellow)};
  }

  svg {
    width: 18px;
    height: 18px;
  }

  @media (min-width: ${max1025}) {
    width: 64px;
    height: 64px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

const AutoCompleteInput = ({ getAvailableDates, setLoading, setSoftErrors, setAddNewAddress, badAddress, setBadAddress, inCta }) => {
  const { useProd } = useContext(DefaultContext);
  const { cmsState } = useContext(StateContext);

  const { availabilityCheck } = cmsState['GetStartedPage'] || [];

  // TODO: Temporary fix while we get Availability Check hooked up to Tempo properly
  const { eligibilityInputLabel, eligibilityInputFootnote } = availabilityCheck || {
    illustrationGroup: [
      {
        illustration: 'football',
        uid: 'kEv-hS5z',
      },
      {
        illustration: 'fruitBowl',
        uid: 'QS8TR_pD',
      },
      {
        illustration: 'blueberries',
        uid: 'Po0GYqRn',
      },
      {
        illustration: 'avocado',
        uid: 'qkkWuZZ3',
      },
      {
        illustration: 'gloves',
        uid: 'MW0-7aOI',
      },
    ],
    title: 'Try a free 30 days<sup>1</sup> of unlimited delivery into your home',
    subtitle: '<strong>See if we deliver to you:</strong>',
    eligibilityInputLabel: 'Enter your full home address',
    eligibilityInputFootnote: 'e.g. 1000 NE Sam Walton Ln, Lee’s Summit, MO, 64086<br /> Note: free InHome trial not available for current & past Walmart+ members.',
    eligibleTitle: 'We deliver to you! Sign up & get $25.',
    eligibleSubtitle: 'Start your free trial<sup>3</sup> & get a $25 gift card<sup>4</sup> with your first InHome order! Stay for just $19.95/mo after.',
    eligibleDisclaimer: '<sup>3</sup>Current Walmart+ members not eligible for InHome free trial. <a href="/fyi/walmart-plus">Learn more</a> about upgrading membership.',
    ineligibleTitle: "Sorry, we don't deliver there yet.",
    ineligibleSubtitle: 'Be the first to know when InHome makes it to your area.',
    inHomeCustomerTitle: "Let's check off that shopping list",
    inHomeCustomerSubtitle: "Get more done at home with inHome, while you're not home. Order your groceries online and have us deliver.",
  };

  const [address, setAddress] = useState('');
  const [addressValid, setAddressValid] = useState(true);

  const [focused, setFocused] = useState(false);

  const addressInputRef = useRef(null);

  const newAddress = (address) => {
    setLoading(true);

    geocodeByAddress(address).then(async (results) => {
      let storageAddress = {};

      results[0].address_components.map((item) => {
        storageAddress = Object.assign({}, storageAddress, { [item.types[0]]: item.short_name });
      });

      const { street_number, route, country, administrative_area_level_1, locality, political, postal_code } = storageAddress;

      let tempAddress = {
        addressLineOne: street_number !== undefined && street_number && route !== undefined && route ? `${street_number} ${route}` : route !== undefined && route ? route : '',
        addressLineTwo: null,
        country: country && country === 'US' ? 'USA' : country,
        state: administrative_area_level_1 && administrative_area_level_1,
        city: (locality || political) && locality ? locality : political,
        postalCode: postal_code && postal_code,
      };

      const eligibility = await eligibilityCheck(tempAddress, useProd).then(async (data) => {
        if (typeof data.errors === 'undefined' || data.errors.length > 0) {
          if (data.status === 404) {
            setBadAddress(true);
            setAddNewAddress(true);
            return;
          }

          setSoftErrors(data.errors || [{ message: 'Oops, something went wrong. Please try again.' }]);

          setTimeout(() => {
            setSoftErrors([]);
          }, 4300);

          return null;
        }
        setAddNewAddress(false);

        return data.data;
      });

      let formattedAddress = {
        ...tempAddress,
        eligible: eligibility && eligibility.eligible,
        storeId: eligibility && eligibility.storeId,
        partnership: eligibility && eligibility.partnership,
      };

      const addressString = `${formattedAddress.city}, ${formattedAddress.state}`;

      gaLogEvent('Eligibility check', addressString);
      gaLogEvent('Eligible', formattedAddress.eligible ? 'yes' : 'no');
      getAvailableDates(formattedAddress);
    });
  };

  const searchOptions = {
    types: ['address'],
  };

  return (
    <StyledAutoCompleteWrapper inCta={inCta}>
      <PlacesAutocomplete
        shouldFetchSuggestions={!isNaN(parseFloat(address.slice(0, 1))) && address.length > 5}
        highlightFirstSuggestion={true}
        searchOptions={searchOptions}
        value={address}
        onChange={(a) => setAddress(a)}
        onSelect={(a, suggestions) => {
          if (suggestions && suggestions[0]) {
            if (a.length > 5) {
              if (badAddress) setBadAddress(false);
              setAddressValid(true);
              newAddress(a);
            } else {
              setBadAddress(true);
            }
          }
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <AddressInputWrapper>
              <InputWrapper>
                <AddressInput
                  id="autoCompleteInput"
                  active={address.length > 0}
                  tabIndex={0}
                  ref={addressInputRef}
                  {...getInputProps({
                    placeholder: eligibilityInputLabel,
                    onFocus: () => {
                      setFocused(true);
                    },
                  })}
                  onBlur={() => suggestions.length > 0 && setAddress(suggestions[0].description)}
                />
                <MovingPlaceholder placeholder={eligibilityInputLabel} inputRef={addressInputRef} controlledValue={address} wide={true} />
                <InputButton
                  disabled={suggestions.length === 0}
                  onClick={(e) => {
                    e.preventDefault();

                    if (address?.length > 5 && !isNaN(parseFloat(address?.slice(0, 1)))) {
                      if (suggestions && suggestions[0]) {
                        setAddressValid(true);
                        newAddress(address);
                        setFocused(false);
                      } else {
                        setAddressValid(false);
                        setBadAddress(true);
                      }
                    }
                  }}
                >
                  <ArrowRight fill={colors.walmart.blue} />
                </InputButton>
              </InputWrapper>
            </AddressInputWrapper>

            <SuggestionContainer hasSuggestions={suggestions?.length > 0} isLoading={loading}>
              {loading && (
                <Loader>
                  <LoadingSpinner color={colors.inHome.blue} />
                </Loader>
              )}

              {!loading &&
                suggestions?.map((suggestion) => (
                  <Suggestion {...getSuggestionItemProps(suggestion, {})} active={suggestion.active} key={suggestion.placeId}>
                    <span>{suggestion.description}</span>
                  </Suggestion>
                ))}
            </SuggestionContainer>

            <Label focused={focused} addressValid={addressValid} badAddress={badAddress}>
              {!badAddress && !addressValid && focused ? 'Please enter a valid address' : badAddress ? 'We cannot verify the address entered, please try again' : ''}
            </Label>
            <AddressFootnote>
              <span dangerouslySetInnerHTML={{ __html: eligibilityInputFootnote }} />
            </AddressFootnote>
          </>
        )}
      </PlacesAutocomplete>
    </StyledAutoCompleteWrapper>
  );
};

export default AutoCompleteInput;
